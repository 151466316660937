/* You can add global styles to this file, and also import other style files */
@import "ngx-owl-carousel-o/lib/styles/scss/owl.carousel";
@import "ngx-owl-carousel-o/lib/styles/scss/owl.theme.default";

@font-face {
  font-family: "Degular";
  src: url("./assets/fonts/Degular/Degular-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Degular";
  src: url("./assets/fonts/Degular/Degular-Regular.otf");
  font-weight: 400;
}

@font-face {
  font-family: "Degular";
  src: url("./assets/fonts/Degular/Degular-Medium.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Degular";
  src: url("./assets/fonts/Degular/Degular-Semibold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "Degular";
  src: url("./assets/fonts/Degular/Degular-Bold.otf");
  font-weight: 700;
}

body,
* {
  font-family: "Degular";
  margin: 0px;
  box-sizing: border-box;
}
body.rtl {
  direction: rtl !important;
  font-family: "IBM Plex Sans Arabic";

  * {
    font-family: "IBM Plex Sans Arabic";
  }
}

.fa {
  font-family: "FontAwesome" !important;
}

.container {
  width: 1440px;
  margin: 0 auto;
  box-sizing: border-box;

  @media only screen and (min-width: 768px) {
    width: 720px;
  }

  @media only screen and (min-width: 992px) {
    width: 960px;
  }

  @media only screen and (min-width: 1200px) {
    width: 1140px;
  }

  @media only screen and (min-width: 1400px) {
    width: 1320px;
  }

  @media only screen and (max-width: 991.98px) {
    width: 100%;
    padding: 0px 20px !important;
  }
}

.d-flex {
  display: flex;
  @media only screen and (max-width: 991.98px) {
    flex-direction: column;
  }
}
ul {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
}
a {
  text-decoration: none;
}

.no-scroll {
  overflow: hidden !important;
}

.ngx-dropdown-container {
  button.ngx-dropdown-button {
    border-radius: 12px !important;
    border: 1px solid #e0e0e0 !important;
    padding: 19px 32px !important;
    font-size: 16px !important;
    color: #363940 !important;
    height: 64px !important;
    line-height: 1 !important;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;

    @media only screen and (max-width: 991.98px) {
      height: 50px !important;
      font-size: 12px !important;
      padding: 15px 20px;
    }

    .display-text {
      text-align: start !important;
    }
  }

  .ngx-dropdown-list-container {
    padding: 0 !important;
    border-radius: 12px !important;
    top: 50px !important;

    .available-items {
      margin: 0 !important;

      .available-item {
        background-color: #f7f9fc !important;
        padding: 12px 10px !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        color: #4d4d4d !important;

        &:hover {
          background-color: #f4f4f5 !important;
        }
      }
    }

    .selected-items {
      display: none !important;

      & + hr {
        display: none !important;
      }
    }
  }
}
